<template>
  <div>
    <IconSprite />
    <CartSidebar />
    <LoginSidebar />
    <CountrySidebar v-if="isCountrySidebarOpen" />
    <GeoLocatorModal
      v-if="isGeoLocatorModalOpen && locationData"
      :localized-country="locationData"
    />
    <SearchModal />
    <ScrollToTop />
    <LazyHydrate when-visible>
      <Notification />
    </LazyHydrate>
    <TopBar :check-sticky="checkSticky" />
    <AppHeader :check-sticky="checkSticky" />

    <div id="layout">
      <nuxt :key="route.fullPath" />
    </div>
    <LazyHydrate v-if="!hidePrefooter && !hidePrefooterForMobile" when-visible>
      <PreFooter />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <AppFooter />
    </LazyHydrate>
    <LazyHydrate when-visible>
      <AppFooterBottom />
    </LazyHydrate>
    <SetCjCookie />
  </div>
</template>

<script>
import LazyHydrate from 'vue-lazy-hydration';
import {
  ref,
  useRoute,
  useFetch,
  onMounted,
  defineComponent,
  useContext,
  computed,
  useMeta,
} from '@nuxtjs/composition-api';
import { useCart, useUser } from '@gemini-vsf/composables';
import AppHeader from '~/components/AppHeader.vue';
import TopBar from '~/components/TopBar.vue';
import CartSidebar from '~/components/CartSidebar.vue';
import LoginSidebar from '~/components/MyAccount/LoginSidebar.vue';
import { useGeminiConfiguration } from '~/composables/useGeminiConfiguration';
import Notification from '~/components/Notification.vue';
import AppFooter from '~/components/AppFooter.vue';
import AppFooterBottom from '~/components/AppFooterBottom.vue';
import IconSprite from '~/components/General/IconSprite.vue';
import PreFooter from '~/components/PreFooter.vue';
import CountrySidebar from '~/components/General/CountrySidebar.vue';
import SearchModal from '~/components/Search/SearchModal.vue';
import { useWishlist, useUiState, useConfig, useWindow } from '~/composables';
import SetCjCookie from '~/components/CJ/SetCjCookie.vue';
import ScrollToTop from '~/components/General/ScrollToTop.vue';
import cookieNames from '~/enums/cookieNameEnum';
import { useI18n } from '~/helpers/hooks/usei18n';
import GeoLocatorModal from '~/components/General/GeoLocatorModal.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    GeoLocatorModal,
    ScrollToTop,
    LazyHydrate,
    TopBar,
    SetCjCookie,
    AppHeader,
    AppFooter,
    AppFooterBottom,
    CartSidebar,
    LoginSidebar,
    Notification,
    IconSprite,
    PreFooter,
    CountrySidebar,
    SearchModal,
  },
  head: {},
  setup() {
    const route = useRoute();
    const { isAuthenticated, user, load: loadUser } = useUser();
    const { load: loadCart } = useCart();
    const { loadWishlist } = useWishlist();
    const { $cookies } = useContext();
    const trans = useI18n();
    const { loadConfig, siteConfigData } = useConfig();
    const flag = ref(false);
    const checkSticky = ref(false);
    const { loadConfiguration } = useGeminiConfiguration();
    const cookiebot = process && process.browser && window && window.Cookiebot;
    const scroll = () => {
      window.addEventListener('scroll', () => {
        const st = window.scrollY;
        checkSticky.value = st >= Number.parseInt('30', 10);
      });
    };
    const {
      isCountrySidebarOpen,
      isGeoLocatorModalOpen,
      toggleGeoLocatorModal,
      toggleCountrySidebar,
    } = useUiState();

    const geolocatorCookieData = ref(null);
    const locationData = computed(() => {
      return { ...geolocatorCookieData.value?.locationData };
    });
    onMounted(async () => {
      geolocatorCookieData.value = $cookies.get(
        cookieNames.geolocatorCookieName
      );
      if (
        geolocatorCookieData.value &&
        !geolocatorCookieData.value.interacted
      ) {
        toggleGeoLocatorModal();
      } else if (
        !localStorage.getItem('vsf-country-selected') &&
        !geolocatorCookieData.value
      ) {
        toggleCountrySidebar();
      }
      if (cookiebot) {
        window.addEventListener(
          'CookiebotOnDecline',
          () => {
            if (window.CookiebotDialog) window.location.reload();
          },
          false
        );
      }
      await loadUser();
      loadCart({
        customQuery: !isAuthenticated.value
          ? {
              cart: 'cartCustom',
            }
          : {
              customerCart: 'customerCartCustom',
            },
      });
      loadWishlist();
      scroll();
    });

    useFetch(async () => {
      await loadConfig();
      await loadConfiguration();
    });

    useMeta({
      htmlAttrs: {
        lang: route?.value?.fullPath?.split('/')?.[1] === 'it' ? 'it' : 'en',
      },
    });

    const { isDesktop, isMobileOrTablet } = useWindow();
    const hidePrefooter = computed(
      () => isDesktop.value && route.value?.meta?.hidePrefooterForDesktop
    );
    const hidePrefooterForMobile = computed(
      () => isMobileOrTablet.value && route.value?.meta?.hidePrefooterForMobile
    );

    return {
      route,
      hidePrefooter,
      hidePrefooterForMobile,
      isAuthenticated,
      flag,
      user,
      checkSticky,
      siteConfigData,
      isCountrySidebarOpen,
      isGeoLocatorModalOpen,
      locationData,
    };
  },
});
</script>

<style lang="scss">
@import '~@storefront-ui/vue/styles';

#layout {
  box-sizing: border-box;
  max-width: 90rem;
  margin: 0 auto;
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

// Reset CSS
html {
  width: auto;
  @media (max-width: var(--tablet-max)) {
    overflow-x: hidden;
  }
}

*:focus,
*:focus-visible {
  outline: none;
}

body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: var(--c-link);

  &:hover {
    color: var(--c-link-hover);
  }
}

h1 {
  font-family: var(--font-family--secondary);
  font-size: var(--h1-font-size);
  line-height: 1.6;
  margin: 0;
}

h2 {
  font-family: var(--font-family--secondary);
  font-size: var(--h2-font-size);
  line-height: 1.6;
  margin: 0;
}

h3 {
  font-family: var(--font-family--secondary);
  font-size: var(--h3-font-size);
  line-height: 1.6;
  margin: 0;
}

h4 {
  font-family: var(--font-family--secondary);
  font-size: var(--h4-font-size);
  line-height: 1.6;
  margin: 0;
}

.sf-sidebar {
  .sf-overlay {
    z-index: 4;
  }
  &__aside {
    z-index: 5;
  }
}

#CookiebotWidget {
  z-index: 999999 !important;
}
</style>
