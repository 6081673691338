<template>
  <div id="error" class="page-404">
    <h1 :style="{ display: 'none' }">{{ $t('Not found') }}</h1>
    <div class="title-404">
      <h2>{{ $t('Oops!') }}</h2>
    </div>
    <div class="body-404">
      <h2>{{ $t('The page you are looking for does not exist.') }}</h2>
      {{
        $t(
          'If you typed the URL directly, make sure the spelling is correct. If you clicked on a link to get here, the link is outdated.'
        )
      }}
    </div>
    <div>
      <p>
        {{
          $t(
            'You can click on the following links to browse the website correctly!'
          )
        }}
      </p>
      <div class="button-404">
        <p>
          <nuxt-link :to="$fixUrlForCurrentLocale('/')">
            {{ $t('Homepage') }}
          </nuxt-link>
        </p>
        <p>
          <nuxt-link
            :to="
              $fixUrlForCurrentLocale(
                locale.includes('it') ? '/contatti' : '/contacts'
              )
            "
          >
            {{ $t('Contact us') }}
          </nuxt-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  useMeta,
  computed,
  useRouter,
  useContext,
  defineComponent,
  useRoute,
} from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';

export default defineComponent({
  name: 'ErrorLayout',
  // Forcing layout change on page leave.
  layout: 'errorLayout',
  components: {},
  props: {},
  head: {},
  setup() {
    const route = useRoute();
    const router = useRouter();
    const { app } = useContext();
    const { locale } = useI18n();

    const { title: metaTitle } = useMeta();
    metaTitle.value = computed(() => app.i18n.t('Not found'));

    useMeta({
      htmlAttrs: {
        lang: route?.value?.fullPath?.split('/')?.[1] === 'it' ? 'it' : 'en',
      },
    });

    return {
      locale,
      router,
    };
  },
});
</script>

<style lang="scss" scoped>
*:focus,
*:focus-visible {
  outline: none;
}

#error {
  margin: 0 auto;
  margin-top: 50px;
  padding: 0 84px 135px 84px;
  max-width: 1366px;
  @media screen and (max-width: 768px) {
    padding: 0 24px 50px 24px;
  }

  h1 {
    font-size: var(--h1-font-size);
  }

  h4,
  h4 span,
  strong {
    font-size: var(--h4-font-size);
  }

  p {
    line-break: anywhere;
  }

  .body-404,
  .title-404 {
    h3 {
      text-transform: uppercase;
    }
  }
}

.page-404 {
  text-align: center;

  .button-404 {
    width: 100%;

    p {
      display: inline-block;
      background: var(--c-secondary);
      padding: 20px 22px;
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 13px;
      width: 250px;

      &:first-of-type {
        margin-right: 50px;
        @include for-mobile {
          margin: 0 auto;
        }
      }

      &:last-of-type {
        margin-left: 50px;
        @include for-mobile {
          margin: 0 auto;
          margin-top: 25px;
        }
      }

      a {
        color: #fff;
        font-weight: 700;
        font-family: 'Spartan';
        text-transform: uppercase;
      }
    }
  }
}
</style>
